@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(22, 20, 20, 0.992);
  color: rgba(255, 255, 255, 0.868);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(22, 20, 20, 0.82);
  color: rgba(255, 255, 255, 0.868);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.hamburger-span {
  background: rgba(255, 255, 255, 0.868);
  display: block;
  width: 2rem;
  height: 0.125rem;
}

@keyframes dropDownAnimation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.tile {
  position: relative;
  width: 200px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  overflow: hidden;
}

.tile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s, opacity 0.5s;
}

.tile.logo img {
  opacity: 1;
  transform: translateY(-100%);
}

.tile.transition img {
  opacity: 1;
  transform: translateY(0);
}

/* HamburgerMenu.css */
.hamburger-menu {
  position: relative;
}

.hamburger-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  padding: 1px;
  z-index: 1;
}

.bar {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.868);
  transition: 0.3s;
}

.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open .bar:nth-child(2) {
  opacity: 0;
}

.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.menu-items {
  position: absolute;
  top: 0;
  right: 40px;
  width: 250px;
  display: none;
}

/* .open .menu-items {
  display: block;
} */

.menu-overlay {
  position: fixed;
  top: 100px;
  right: 0;
  width: 26%; /* Adjust the width to cover 1/2 of the screen */
  height: 53% ; /* Adjust the height to cover 1/3 of the screen */
  background-color: rgba(22, 20, 20, 0.992);
  z-index: 1000;
  transform: translateX(120%);
  transition: transform 0.3s ease-in-out;
  display: flex; /* Add display flex to the overlay */
  align-items: center; /* Center vertically */
  justify-content: flex-start; /* Start from the top */
}

.open .menu-overlay {
  transform: translateX(0);
}

.open .menu-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

}

@media (max-width: 1024px) {
  .menu-overlay {
    width: 27%;
    height: 43%;
  }
}

@media (max-width: 768px) {
  .menu-overlay {
    width: 40%;
  }
}

@media (max-width: 425px) {
  .menu-overlay {
    width: 45%;
    height: 55%;
  }
}

@media (max-width: 375px) {
  .menu-overlay {
    width: 55%;
    height: 60%;
  }
}

@media (max-width: 320px) {
  .menu-overlay {
    width: 60%;
    height: 61%;
  }
}

/* @media (max-width: 425px) {
  .menu-overlay {
    position: fixed;
    top: 100px;
    right: 0;
    width: 50%; 
    height: 47%; 
    background-color: rgba(22, 20, 20, 0.992);
    z-index: 1000;
    transform: translateX(120%);
    transition: transform 0.3s ease-in-out;
    display: flex; 
    align-items: center; 
    justify-content: flex-start; 
  }

  .open .menu-overlay {
    transform: translateX(0);
  }

  .open .menu-items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

  }

} */
